<template>
  <div>
    <h3 ref="intro">
      Venn Diagram
    </h3>
    <p>
      A Venn diagram is a graphical way to represent sets, relationships amongst sets, and mathematical operations on sets. Below are some basic definitions.
    </p>
    <ul class="a">
      <li> <h5> Definition of a Set </h5> </li>
      <p>
        A set represents a collection of objects that share a common characteristics.
        For example &mdash; A ={2, 4, 6, 8, 10, 12} represents the set of even numbers. Every element of this set is an even number. Let's take another example &mdash;
        B ={3, 6, 9, 12, 15} represents the set of multiples of 3.
      </p>
      <li> <h5> Union of Sets </h5> </li>
      <p>
        The union of sets A and B is the set that contains all the elements of set A and that of set B &mdash; however the common elements are taken only once.
        The union of sets A and B is written as A ⋃ B.
      </p>
      <li> <h5>Intersection of Sets </h5> </li>
      <p>
        The intersection of sets A and B is the set that contains the elements which are common to both A and B.
        The intersection of sets A and B is written as A ⋂ B.
      </p>
    </ul>
    <h3 ref="pg">
      MagicGraph | Venn Diagram
    </h3>
    <p>
      In this MagicGraph, you will learn about graphically representing sets using Venn diagrams.
      Hover over a set definition to see it Venn diagram representation.
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'VennDiagram',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Venn Diagram';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Venn Diagram', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo:
        { title: 'Venn Diagram',
          meta: [
                  {vmid:'description', name: 'description', content: 'Learn interactively about Venn Diagram'}
                ]
         }
}
</script>
