import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeSliderSwitch,
    placeLogo
} from '../Utils';
const Boxes = {
    box1: function () {
      JXG.Options.point.showInfoBox=false;
      JXG.Options.point.highlight=false;
      JXG.Options.text.highlight=false;
      JXG.Options.text.fixed=true;
      JXG.Options.curve.highlight=false;
      JXG.Options.circle.highlight=false;
      JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
      var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-9, 9, 9, -9],
          keepaspectratio: true, axis:false, ticks:{visible:false},
          grid:true, showCopyright:false, showNavigation:false,
          pan:{enabled:false}, zoom:{enabled:false}});
      brd1.suspendUpdate();
      //Make Responsive
      makeResponsive(brd1);
      //Place Title
      placeTitle(brd1, 'Graphical Representation of Sets', '(Venn Diagram)');
      //
      placeLogo(brd1);
      //Variables
      var radius = 2.5;
      var ax = -2;
      var ay = -1.5;
      var bx = 2;
      var by = -1.5;
      var cx = 0;
      var cy = 1.5;

      var wpol = 5;
      var hpol = 5;

      var color1 = 'OldLace';
      var color2 = '#FF3B3B ';
      var color3 = '#FF3B3B ';
      var color4 = 'OldLace ';
      var colorx = 'red';
      var colory = 'green';
      var colorz = 'blue';


      //Slider
      //var slid = brd1.create('slider',[[-5,-8],[5,-8],[1,1,20]], {snapWidth: 1 ,face:'^', size:8, strokeWidth: 2, name:'', strokeColor:'Black', fillColor: color2, withTicks: false,  baseline: { strokeColor: color2, strokeWidth: 3}, highline: { strokeColor: color2}, postLabel: '', withLabel:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

      JXG.joinCurves = function(board, parents, attributes) {
          var curves = parents,
              attr = JXG.copyAttributes(attributes, board.options, 'curve'),
              c = board.create('curve', [[0], [0]], attr);

          c.updateDataArray = function() {
              var i = curves.length;

              // The paths have to be connected
              this.dataX = [];
              this.dataY = [];
              for (i = 0; i < curves.length; i++) {
                  if (i < curves.length - 1) {
                      this.dataX = this.dataX.concat(curves[i].dataX.slice(0,-1));
                      this.dataY = this.dataY.concat(curves[i].dataY.slice(0,-1));
                  } else {
                      this.dataX = this.dataX.concat(curves[i].dataX);
                      this.dataY = this.dataY.concat(curves[i].dataY);
                  }
              }

              if (this.dataX.length<4) {
                  this.bezierDegree = 1;
              } else {
                  this.bezierDegree = curves[0].bezierDegree;
              }
          };
          c.prepareUpdate().update().updateVisibility().updateRenderer();
          return c;
      };

      //Tetxs
      //var txt_A = brd1.create('text', [ax, ay, 'A'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);
      //var txt_B = brd1.create('text', [bx, by, 'B'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);
      //var txt_C = brd1.create('text', [cx, cy, 'C'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);

      //Polygon
    //  var universe = brd1.create('polygon', [[-wpol, -hpol], [wpol, -hpol], [wpol, hpol], [-wpol, hpol]],
    //  {visible: true, vertices:{visible:false, fixed:true}, borders:{visible:false, strokeColor: 'black', strokeWidth: 6, highlightStrokeColor: 'black'}, fillColor: color2, highlightfillColor: color2, highlightfillOpacity: 1.0, fillOpacity:0.0 , layer: 2});
      var universe =brd1.create('circle', [[0,0], [7, 0]], {visible:false, fillOpacity:1, dash:1, strokeColor: 'black', strokeWidth: 1, fillColor: color4, layer: 2, highlightStrokeColor: 'black', highlightfillColor: color2})
      // Create two arcs (or circles)
      var p1 = brd1.create('point', [ax, ay], {face:'o' , name:'p1', strokeColor: 'black', fillColor: '#5B43FF',  size:3, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var p2 = brd1.create('point', [ax + radius, ay], {face:'o' , name:'p2', strokeColor: 'black', fillColor: 'red',  size:3, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var a1 = brd1.create('circle', [p1,p2], {fillOpacity:1, dash:1, strokeColor: 'black', strokeWidth: 1, fillColor: color4, layer: 2, highlightStrokeColor: 'black', highlightfillColor: color2});

      var p3 = brd1.create('point', [bx, by], {face:'o' , name:'p3', strokeColor: 'black', fillColor: '#5B43FF',  size:3, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var p4 = brd1.create('point', [bx + radius, by], {face:'o' , name:'p4', strokeColor: 'black', fillColor: 'red',  size:3, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var a2 = brd1.create('circle', [p3,p4], {fillOpacity:1, dash:1, strokeColor: 'black', strokeWidth: 1, fillColor: color4, layer: 2, highlightStrokeColor: 'black', highlightfillColor: color2});

      var p5 = brd1.create('point', [cx, cy], {face:'o' , name:'p5', strokeColor: 'black', fillColor: '#5B43FF',  size:3, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var p6 = brd1.create('point', [cx + radius, cy], {face:'o' , name:'p6', strokeColor: 'black', fillColor: 'red',  size:3, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var a3 = brd1.create('circle', [p5,p6], {fillOpacity:1, dash:1, strokeColor: 'black', strokeWidth: 1, fillColor: color4, layer: 2, highlightStrokeColor: 'black', highlightfillColor: color2});


      // Create two intersection points
      var i1 = brd1.create('intersection', [a1,a2,1], {visible:false, name:'i1', label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var i2 = brd1.create('intersection', [a1,a3,0], {visible:false, name:'i2', label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var i3 = brd1.create('intersection', [a2,a3,1], {visible:false, name:'i3', label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var i4 = brd1.create('intersection', [a1,a2,0], {visible:false, name:'i4', label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var i5 = brd1.create('intersection', [a1,a3,1], {visible:false, name:'i5', label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var i6 = brd1.create('intersection', [a2,a3,0], {visible:false, name:'i6', label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});


      // Create three arcs surrounding the intersection area
      var c1 = brd1.create('arc', [p1, i2, i1], {visible:false, strokeColor: 'black', dash:1, strokeWidth: 1});
      var c2 = brd1.create('arc', [p3, i1, i3], {visible:false, strokeColor: 'black',dash:1,  strokeWidth: 1});
      var c3 = brd1.create('arc', [p5, i3, i2], {visible:false, strokeColor: 'black', dash:1, strokeWidth: 1});

      // Create three arcs surrounding the intersection area AC
      var c4 = brd1.create('arc', [p1, i2, i5], {visible:true, strokeColor: 'black', dash:1, strokeWidth: 1});
      var c5 = brd1.create('arc', [p5, i5, i2], {visible:true, strokeColor: 'black', dash:1, strokeWidth: 1})

      // Create three arcs surrounding the intersection area BC
      var c6 = brd1.create('arc', [p3, i6, i3], {visible:true, strokeColor: 'black', dash:1,  strokeWidth: 1});
      var c7 = brd1.create('arc', [p5, i3, i6], {visible:true, strokeColor: 'black', dash:1, strokeWidth: 1})

      // Create three arcs surrounding the intersection area BC
      var c8 = brd1.create('arc', [p1, i4, i1], {visible:true, strokeColor: 'black', dash:1, strokeWidth: 1});
      var c9 = brd1.create('arc', [p3, i1, i4], {visible:true, strokeColor: 'black', dash:1, strokeWidth: 1})


      // Join the three arcs and fill the area.

      var AC = JXG.joinCurves(brd1, [c4, c5],
          {fillOpacity:1, fillColor:color1,  strokeColor: 'black', strokeWidth: 0
      });

      var BC = JXG.joinCurves(brd1, [c7, c6],
          {fillOpacity:1,  fillColor:color1,  strokeColor: 'black', strokeWidth: 0
      });


      var AB = JXG.joinCurves(brd1, [c9, c8],
          {fillOpacity:1,  fillColor:color1,  strokeColor: 'black', strokeWidth: 0
      });

      var ABC = JXG.joinCurves(brd1, [c1, c2, c3],
          {fillOpacity:1,  fillColor:color1,  strokeColor: 'black', strokeWidth: 0
      });

      //Individual Sets
      var txt1 = brd1.create('text', [0, 6, ''],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
      var txt2 = brd1.create('text', [0, -6, 'Tap on a set name to see its Venn diagram'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
      var txtA = brd1.create('text', [-8, -8, 'A'],{color:colorx, visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}});
      var txtAtxt = brd1.create('text', [p1.X(), p1.Y(), 'A'],{color:'black', visible:false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}});
      txtA.on('down', function(){FunA();});
      //
      var txtB = brd1.create('text', [-7, -8, 'B'],{color:colorx,visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}});
      var txtBtxt = brd1.create('text', [p3.X(), p3.Y(), 'B'],{color:'black', visible:false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}});
      txtB.on('down', function(){FunB();});
      //
      var txtC = brd1.create('text', [-6, -8, 'C'],{color:colorx,visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}});
      var txtCtxt = brd1.create('text', [p5.X(), p5.Y(), 'C'],{color:'black', visible:false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}});
      txtC.on('down', function(){FunC();});
      //
      //var txtAprime = brd1.create('text', [-5, -8, 'A\''],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);
    //  txtAprime.on('down', function(){FunC();});
      //
      //var txtBprime = brd1.create('text', [-4, -8, 'B\''],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);
      //txtAprime.on('down', function(){FunC();});
      //
    //  var txtCprime = brd1.create('text', [-3, -8, 'C\''],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);
      //txtAprime.on('down', function(){FunC();});
      //Union of Sets
      var txtAUB = brd1.create('text', [-4., -8, 'A ⋃ B'],{color:colory,visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}});
      txtAUB.on('down', function(){FunAUB();});
      //
      var txtBUC = brd1.create('text', [-2, -8, 'B ⋃ C'],{color:colory,visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}});
      txtBUC.on('down', function(){FunBUC();});
      //
      var txtCUA = brd1.create('text', [0., -8, 'C ⋃ A'],{color:colory,visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}});
      txtCUA.on('down', function(){FunAUC();});
//
//
      //Intersection of Sets
      var txtAOB = brd1.create('text', [3.0, -8, 'A ⋂ B'],{color:colorz,visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}});
      txtAOB.on('down', function(){FunAOB();});
      //
      var txtBOC = brd1.create('text', [5, -8, 'B ⋂ C'],{color:colorz,visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}});
      txtBOC.on('down', function(){FunBOC();});
      //
      var txtCOA = brd1.create('text', [7, -8, 'C ⋂ A'],{color:colorz,visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}});
      txtCOA.on('down', function(){FunAOC();});
      //Functions
      function FunA(){
          white();
          a1.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
          AC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
          AB.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
          ABC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
          txt1.setText('Highlighted region is set A');
          txtAtxt.setAttribute({visible:true});
        }
  //
      function FunB(){
      white();
      a2.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
      BC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
      AB.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
      ABC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
      txt1.setText('Highlighted region is set B');
      txtBtxt.setAttribute({visible:true});
    }
//
      function FunC(){
        white();
        a3.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
        AC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
        BC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
        ABC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
        txt1.setText('Highlighted region is set C');
        txtCtxt.setAttribute({visible:true});
      }
//
      function FunAUB(){
        white();
        a1.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        a2.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        AB.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        AC.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        BC.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        ABC.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        txt1.setText('Highlighted region is union of sets A and B');
        txtAtxt.setAttribute({visible:true});
        txtBtxt.setAttribute({visible:true});
        }
//
      function FunBUC(){
        white();
        a2.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        a3.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        AB.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        AC.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        BC.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        ABC.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        txt1.setText('Highlighted region is union of sets B and C');
        txtBtxt.setAttribute({visible:true});
        txtCtxt.setAttribute({visible:true});
      }
//
      function FunAUC(){
        white();
        a1.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        a3.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        AB.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        AC.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        BC.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        ABC.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        txt1.setText('Highlighted region is union of sets A and C');
        txtAtxt.setAttribute({visible:true});
        txtCtxt.setAttribute({visible:true});
      }
//
      function FunAOB()
      {
        white();
        AB.setAttribute({fillOpacity:1, dash:0,fillColor:colorz, highlightfillColor: colorz});
        ABC.setAttribute({fillOpacity:1, dash:0,fillColor:colorz, highlightfillColor: colorz});
        txt1.setText('Highlighted region is intersection of sets A and B');
        txtAtxt.setAttribute({visible:true});
        txtBtxt.setAttribute({visible:true});
      }

      function FunAOC()
      {
        white();
        AC.setAttribute({fillOpacity:1, dash:0,fillColor:colorz, highlightfillColor: colorz});
        ABC.setAttribute({fillOpacity:1, dash:0,fillColor:colorz, highlightfillColor: colorz});
        txt1.setText('Highlighted region is intersection of sets A and C');
        txtAtxt.setAttribute({visible:true});
        txtCtxt.setAttribute({visible:true});
      }
//
      function FunBOC()
      {
        white();
        BC.setAttribute({fillOpacity:1, dash:0,fillColor:colorz, highlightfillColor: colorz});
        ABC.setAttribute({fillOpacity:1, dash:0,fillColor:colorz, highlightfillColor: colorz});
        txt1.setText('Highlighted region is intersection of sets B and C');
        txtBtxt.setAttribute({visible:true});
        txtCtxt.setAttribute({visible:true});
      }
      //slid.on('drag',function(){ idk();});
/*
      function idk(){

          white();

          if(slid.Value() == 20){
              a1.setAttribute({fillOpacity:1, dash:0, fillColor:color2, highlightfillColor: color2});
              a2.setAttribute({fillOpacity:1, dash:0, fillColor:color2, highlightfillColor: color2});
              a3.setAttribute({fillOpacity:1, dash:0, fillColor:color2, highlightfillColor: color2});
              AB.setAttribute({fillOpacity:1, dash:0, fillColor:color2, highlightfillColor: color2});
              AC.setAttribute({fillOpacity:1, dash:0, fillColor:color2, highlightfillColor: color2});
              BC.setAttribute({fillOpacity:1, dash:0, fillColor:color2, highlightfillColor: color2});
              ABC.setAttribute({fillOpacity:1, dash:0, fillColor:color2, highlightfillColor: color2});
              universe.setAttribute({fillOpacity:1, dash:0, fillColor:color2, highlightfillColor: color2});
              txt1.setText('U');
          }
          else if(slid.Value() == 2){
              a1.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
              AC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
              AB.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
             ABC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
              txt1.setText('A');
          }
          else if(slid.Value() == 3){
              a2.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
              BC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
              AB.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
              ABC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
              txt1.setText('B');
          }
          else if(slid.Value() == 4){
              a3.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
              AC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
              BC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
              ABC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
              txt1.setText('C');
          }
          else if(slid.Value() == 5){
              a2.setAttribute({fillOpacity:1,fillColor:color2, highlightfillColor: color2});
              a3.setAttribute({fillOpacity:1,fillColor:color2, highlightfillColor: color2});
              BC.setAttribute({fillOpacity:1,fillColor:color2, highlightfillColor: color2});
              universe.setAttribute({fillOpacity:1,fillColor:color2, highlightfillColor: color2});
              txt1.setText("A'");
          }
          else if(slid.Value() == 6){
              a1.setAttribute({fillOpacity:1,fillColor:color2, highlightfillColor: color2});
              a3.setAttribute({fillOpacity:1,fillColor:color2, highlightfillColor: color2});
              AC.setAttribute({fillOpacity:1,fillColor:color2, highlightfillColor: color2});
              universe.setAttribute({fillOpacity:1,fillColor:color2, highlightfillColor: color2});
              txt1.setText("B'");
          }
          else if(slid.Value() == 7){
              a1.setAttribute({fillColor:color2, highlightfillColor: color2});
              a2.setAttribute({fillColor:color2, highlightfillColor: color2});
              AB.setAttribute({fillColor:color2, highlightfillColor: color2});
              universe.setAttribute({fillColor:color2, highlightfillColor: color2});
              txt1.setText("C'");
          }
          else if(slid.Value() == 8){
              a1.setAttribute({fillColor:color2, highlightfillColor: color2});
              a2.setAttribute({fillColor:color2, highlightfillColor: color2});
              AB.setAttribute({fillColor:color2, highlightfillColor: color2});
              AC.setAttribute({fillColor:color2, highlightfillColor: color2});
              BC.setAttribute({fillColor:color2, highlightfillColor: color2});
              ABC.setAttribute({fillColor:color2, highlightfillColor: color2});
              txt1.setText('A ⋃ B');
          }
          else if(slid.Value() == 9){
              a1.setAttribute({fillColor:color2, highlightfillColor: color2});
              a3.setAttribute({fillColor:color2, highlightfillColor: color2});
              AB.setAttribute({fillColor:color2, highlightfillColor: color2});
              AC.setAttribute({fillColor:color2, highlightfillColor: color2});
              BC.setAttribute({fillColor:color2, highlightfillColor: color2});
              ABC.setAttribute({fillColor:color2, highlightfillColor: color2});
              txt1.setText('A ⋃ C');
          }
          else if(slid.Value() == 10){
              a2.setAttribute({fillColor:color2, highlightfillColor: color2});
              a3.setAttribute({fillColor:color2, highlightfillColor: color2});
              AB.setAttribute({fillColor:color2, highlightfillColor: color2});
              AC.setAttribute({fillColor:color2, highlightfillColor: color2});
              BC.setAttribute({fillColor:color2, highlightfillColor: color2});
              ABC.setAttribute({fillColor:color2, highlightfillColor: color2});
              txt1.setText('B ⋃ C');
          }

          else if(slid.Value() == 11){
              AB.setAttribute({fillColor:color2, highlightfillColor: color2});
              ABC.setAttribute({fillColor:color2, highlightfillColor: color2});
              txt1.setText('A ⋂ B');
          }
          else if(slid.Value() == 12){
              AC.setAttribute({fillColor:color2, highlightfillColor: color2});
              ABC.setAttribute({fillColor:color2, highlightfillColor: color2});
              txt1.setText('A ⋂ C');
          }
          else if(slid.Value() == 13){
              BC.setAttribute({fillColor:color2, highlightfillColor: color2});
              ABC.setAttribute({fillColor:color2, highlightfillColor: color2});
              txt1.setText('B ⋂ C');
          }

          else if(slid.Value() == 14){
              a1.setAttribute({fillColor:color2, highlightfillColor: color2});
              AC.setAttribute({fillColor:color2, highlightfillColor: color2});
              txt1.setText('A - B');
          }
          else if(slid.Value() == 15){
              a2.setAttribute({fillColor:color2, highlightfillColor: color2});
              BC.setAttribute({fillColor:color2, highlightfillColor: color2});
              txt1.setText('B - A');
          }

          else if(slid.Value() == 16){
              a2.setAttribute({fillColor:color2, highlightfillColor: color2});
              AB.setAttribute({fillColor:color2, highlightfillColor: color2});
              txt1.setText('B - C');
          }

          else if(slid.Value() == 17){
              a3.setAttribute({fillColor:color2, highlightfillColor: color2});
              AC.setAttribute({fillColor:color2, highlightfillColor: color2});
              txt1.setText('C - B');
          }
          else if(slid.Value() == 18){
              a3.setAttribute({fillColor:color2, highlightfillColor: color2});
              BC.setAttribute({fillColor:color2, highlightfillColor: color2});
              txt1.setText('C - A');
          }
          else if(slid.Value() == 19){
              a1.setAttribute({fillColor:color2, highlightfillColor: color2});
              AB.setAttribute({fillColor:color2, highlightfillColor: color2});
              txt1.setText('A - C');
          }
          else if(slid.Value() == 1){
              txt1.setText('∅');
          }
          else{
              return;
          }
      }
*/
      function white()
      {
          txtAtxt.setAttribute({visible:false});
          txtBtxt.setAttribute({visible:false});
          txtCtxt.setAttribute({visible:false});
          AB.setAttribute({fillOpacity:1, dash:1,fillColor:color4, highlightfillColor: color1});
          AC.setAttribute({fillOpacity:1, dash:1,fillColor:color4, highlightfillColor: color1});
          BC.setAttribute({fillOpacity:1, dash:1,fillColor:color4, highlightfillColor: color1});
          ABC.setAttribute({fillOpacity:1,dash:1,fillColor:color4, highlightfillColor: color1});
          a1.setAttribute({fillOpacity:1, dash:1,fillColor:color4, highlightfillColor: color1});
          a2.setAttribute({fillOpacity:1, dash:1,fillColor:color4, highlightfillColor: color1});
          a3.setAttribute({fillOpacity:1, dash:1,fillColor:color4, highlightfillColor: color1});
          universe.setAttribute({fillOpacity:1, dash:1,fillColor:color4, highlightfillColor: color1});
      }

      brd1.unsuspendUpdate();
    }
}
export default Boxes;
